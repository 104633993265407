<template>
    <div class="">
        <div
            id="data-list-list-view"
            class="data-list-container"
            v-if="clients"
        >
            <ClientsTable
                :data="clients"
                :itemsPerPage="itemsPerPage"
                @itemsPerPage="setItemsPerPage"
                @search="search"
                @filter="setFilter"
                @edit="setEdit"
                @sort="setSort"
                @refresh="getPage"
                @date="getDateRange"
                :view="filter"
            ></ClientsTable>

            <div class="paginator-container">
                <vs-pagination
                    description
                    goto
                    descriptionTitle="Clients"
                    :sizeArray="clients.count"
                    :description-items="descriptionItems"
                    :total="clients.number_of_pages"
                    :max-items="itemsPerPage"
                    :max="itemsPerPage"
                    v-model="pageNumberComputed"
                    @changeMaxItems="setItemsPerPage"
                ></vs-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import ClientsTable from './Table'
import { http } from '@/services'

export default {
    name: 'Clients',
    components: {
        ClientsTable,
    },
    created() {
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    beforeRouteUpdate(to, from, next) {
        next()
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    watch: {
        pageNumber(newValue, oldValue) {
            if (Number(newValue) !== Number(oldValue))
                this.$router.push({
                    name: 'clients',
                    params: { page: Number(newValue) },
                })
        },
    },
    computed: {
        pageNumberComputed: {
            set(value) {
                this.pageNumber = value
                this.getPage()
            },
            get() {
                return this.pageNumber
            },
        },
    },
    data() {
        return {
            clients: null,
            pageNumber: 1,
            itemsPerPage: 10,
            selected: null,
            sort: null,
            queriedItems: 20,
            searchQuery: null,
            filter: 'all',
            descriptionItems: [5, 10, 20],
            selectedAccount: null,
            show: {
                editModal: false,
            },
            start: null,
            end: null
        }
    },
    methods: {
        setSort(value) {
            this.sort = value
            this.getPage()
        },

        setItemsPerPage(value) {
            this.itemsPerPage = this.descriptionItems[value]
            this.getPage()
        },
        getPage() {
            http.get('clients_list', {
                params: {
                    filter: this.filter,
                    items_per_page: this.itemsPerPage,
                    page_number: this.pageNumber,
                    query: this.searchQuery,
                    view: this.view,
                    sort: this.sort,
                    start: this.start,
                    end: this.end,
                },
            })
                .then((response) => {
                    this.clients = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        search(value) {
            this.searchQuery = value
            this.getPage()
        },
        setFilter(value) {
            this.filter = value
            this.getPage()
        },
        setEdit(value) {
            this.selectedAccount = value
            this.show.editModal = true
        },
        getDateRange(argsList) {
            console.log('getDateRange', argsList)
            this.start = argsList[0]
            this.end = argsList[1]
            this.getPage()
        }
    },
}
</script>
